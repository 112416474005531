@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700;900&display=swap');

:root {
    --color-primary: #435030;
    --color-primary-hover: #607245;
    --color-secondary: #E2E7D5;
    --color-text: #E29529;
    --color-accent: #91230D;
    --color-accent-hover: #b02e14;
    --color-gray: #848484;
}

body {
    font-family: Montserrat;
    overflow: hidden;
}

#app {
    height: 100vh;
    display: flex;
    width: 100%;
}

#levels {
    display: flex;
    flex-direction: column;
    width: calc(100vw - (100vh * 0.6));
    background-color: #ffffff;
}

#image-map-pro-building .imp-canvas {
    width: auto !important;
}
.isDesktop #image-map-pro-building .imp-canvas {
    height: 100vh !important;
}

#image-map-pro-lvl .imp-image {
    object-fit: contain;
}

#image-map-pro-lvl .imp-canvas {
    width: auto !important;
    height: 100% !important;
}

#image-map-pro-lvl {
    bottom: initial;
    left: 50%;
    top: calc(50% - 175px);
    transform: translate(-50%, calc(-50% + 100px));
    position: relative;
    max-height: calc(100vh - 150px) !important;
    max-width: calc((100vh - 150px) * (1600 / 1100));
    /* width: calc((100vw - (100vh * 0.6))); */
}

#image-map-pro-building .imp-container,
#image-map-pro-lvl .imp-container {
    max-width: initial !important;
    width: initial !important;
}

#image-map-pro-lvl .imp-container,
#image-map-pro-lvl .imp-container .imp-canvas-wrap {
    height: 100%;
}

#image-map-pro-lvl .imp-container {
    justify-content: center;

}

#image-map-pro-building .imp-object-poly.imp-object.active {
    fill: var(--color-primary);
    opacity: 0.8;
}

#image-map-pro-lvl .imp-object-text.imp-object[data-title*='t_'] {
    color: var(--color-secondary);
    pointer-events: none;
    font-family: Montserrat;
}

#image-map-pro-lvl .imp-object-poly.imp-object[data-title*='u_'] {
    fill: var(--color-primary);
}

#image-map-pro-lvl .imp-object-poly.imp-object[data-title*='u_']:hover {
    fill: var(--color-primary-hover);
}

#image-map-pro-lvl .imp-object-poly.imp-object[data-title*='u_'].disabled {
    fill: var(--color-accent);
}

#image-map-pro-lvl .imp-object-poly.imp-object[data-title*='u_'].disabled:hover {
    fill: var(--color-accent-hover);
}

#image-map-pro-lvl .imp-object-poly.imp-object[data-title*='u_'].rented {
    /* filter: saturate(0.1) brightness(1.5); */
    fill:var(--color-gray);
}
#image-map-pro-lvl .imp-object-poly.imp-object[data-title*='u_'].rented:hover {
    /* filter: saturate(0.1) brightness(1.5); */
    fill:#a7a7a7;
}

.imp-ui-top-right {
    display: none !important;
}

div.numbers {
    display: block;
    font-size: 2.5vh;
    transition: none;
    pointer-events: none;
}

div.numbers {
    margin-left: -5px;
}

div.numbers.small {
    margin-left: 0px;
}

#byEdge {
    display: block;
    position: absolute;
    bottom: 10px;
    width: 70px;
    height: 23px;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    left: calc((100vh * 0.6) - 80px);
    opacity: 0.25;
    transition: opacity 150ms;
    cursor: pointer;
}

#byEdge:hover {
    opacity: 0.8;
}

#level {
    background-color: var(--color-primary);
    color: var(--color-secondary);
    padding: 15px;
    font-size: 1.5em;
    text-transform: uppercase;
    position: absolute;
    top: 25px;
    left: calc(100vh * 0.6 + 25px)
}

#compass {
    width: 100px;
    height: 80px;
    background-image: url("./icons/compass.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    right: 25px;
    bottom: 175px;
}

/* ----------------------------------------- NUMBERS ------------ */

#overlayUnits {
    width: calc((100vw - (100vh * 0.6)));
    height: calc(100vh - 150px);
    z-index: 100;
    top: 0;
    position: absolute;
    pointer-events: none;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

#overlayBuilding {
    width: calc(100vh * 0.6);
    height: 100vh;
    background: url("../public/images/SYMBIO_Building_Overlay.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 100;
    top: 0;
    position: absolute;
    pointer-events: none;
}

/* ---------------------------------------- INTERFACE ----------- */

#interface {
    background-color: var(--color-primary);
    height: 150px;
    width: calc(100% - (100vh * 0.6));
    padding: 0;
    position: absolute;
    bottom: 0;
}

#interface .top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    margin-bottom: 15px;
    background-color: var(--color-primary-hover);
    height: 50px;
}

.top div:not(.arrow) {
    margin: 0 20px;
    user-select: none;
    color: var(--color-secondary);
    font-weight: 500;
    font-size: 1.25em;
}

.top div:not(.arrow)::first-letter {
    text-transform: capitalize;
}

.top .arrow {
    background-image: url(../src/icons/arrow.svg);
    background-color: var(--color-secondary);
    height: 25px;
    width: 25px;
    background-repeat: no-repeat;
    background-size: 65%;
    background-position: center;
    cursor: pointer;
}

.top .arrow.arrow-l {
    transform: rotate(180deg);
}

.btn {
    color: var(--color-primary);
    margin: 0 10px;
    background-color: var(--color-secondary);
    width: 35px;
    height: 35px;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
}

.btn:first-child {
    margin-left: 0;
}

.btn:last-child {
    margin-right: 0;
}

.btn.togled {
    background: transparent;
    border: var(--color-secondary) 2px solid;
    color: var(--color-secondary);
}

.btn p {
    pointer-events: none;
    line-height: 35px;
    margin: 0;
}

h3 {
    margin: 0;
    margin-right: 15px;
    color: var(--color-secondary);
    font-weight: 600;
    font-size: 1em;
    min-width: 100px;
}

h3::first-letter {
    text-transform: capitalize;
}


*.flex {
    display: flex;
    align-items: center;
}

.flex.buttonDiv,
.flex.rangeDiv {
    height: calc(33% - 15px);
}


.flex.buttonDiv>.flex:not(:first-child) {
    margin-left: 25px;
}

/* ---------------------------------- RANGE */

span.rangeSlider {
    color: var(--color-secondary);
    margin: 0 auto;
    width: calc(100% - 20px);
}

span.css-6cwnna-MuiSlider-mark {
    background-color: var(--color-primary);
}

span.css-eg0mwd-MuiSlider-thumb:hover,
span.css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 0px 0px 8px #E2E7D510;
}

span.css-nnid7-MuiSlider-valueLabel {
    background-color: var(--color-secondary);
    color: var(--color-primary);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.15);
    border-radius: 0;
    top: -15px;
}


div.range-slider .range-slider__thumb {
    background: var(--color-secondary);
}

div.range-slider {
    background: #ffffff55;
}

div.range-slider .range-slider__range {
    background: #ffffff82;
}

div.rangeParent {
    height: calc(100% - 85px);
    width: 100%;
    padding: 0 100px;
}

div.rangeDiv {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin: 0 calc(2vw);
}

/* div.rangeDiv:not(:last-child) {
    margin-right: 25px;
} */

div.rangeDiv div {
    display: flex;
    justify-content: space-between;
}

.rangeDiv p {
    margin: 0;
    font-size: 0.8em;
    color: var(--color-secondary);
    font-weight: 600;
}

.rangeDiv p.offset {
    margin: 0 8px;
}
.rangeDiv p.offset.two {
    margin: 0;
    margin-left: 4px;
}

.rangeDiv h3 {
    margin: 0;
    font-size: 0.8em;
    font-weight: 300;
}

#buttonsLang {
    display: flex;
    position: absolute;
    top: 5px;
    left: 5px;
}

/* ---------------------------------- LEGEND */

#legend{
    position: fixed;
    bottom:165px;
    left:calc(100vh * 0.6 + 15px);
    padding:10px;
    background-color: #ffffff;
    z-index: 499;
}

#legend .legendColor{
    display: flex;  
    align-items: center;  
}
#legend .legendColor:not(:last-child){
    margin-bottom: 10px;  
}
#legend span{
    width:18px;
    height: 18px; 
    margin-right: 10px;
    box-sizing: border-box;
}
#legend span.c_green{
    background-color: var(--color-primary);
}
#legend span.c_red{
    background-color: var(--color-accent);
}
#legend span.c_gray{
    background-color: var(--color-gray);
}
#legend p{
    margin: 0;  
    font-size: 0.65em; 
    font-weight: 600;
}

/* ---------------------------------- TOOLTIP */

div.tooltip {
    position: absolute;
    background-color: var(--color-primary);
    color: var(--color-secondary);
    padding: 15px;
    transform: translate(25px, -50%);
    z-index: 500;
    transition: opacity 150ms, transform 150ms;
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.14);
}


div.tooltip.fadeOut {
    opacity: 0;
    transform: scale(0);
}

div.tooltip.fadeIn {
    opacity: 1;
}

div.tooltip::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-5px, -50%) rotate(45deg);
    border-left: 15px solid var(--color-primary);
    border-top: 15px solid var(--color-primary);
}

div.tooltip>div {
    display: flex;
}

div.tooltip h4,
div.tooltip h5 {
    margin: 0;
    font-weight: 400;
}

div.tooltip p {
    margin: 0;
    text-transform: uppercase;
}

#unitTooltip.tooltip {
    padding: 0;
}

#unitTooltip.tooltip>div:first-child {
    background-color: var(--color-primary-hover);
    padding: 15px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

#unitTooltip.tooltip>div:not(:first-child) {
    padding: 5px 15px;
    justify-content: space-between;
}

#unitTooltip.tooltip>div:not(:first-child) h5::first-letter {
    text-transform: capitalize;
}

#unitTooltip.tooltip>div:not(:first-child) h5 {
    font-weight: 300;
}

#unitTooltip.tooltip>div:not(:first-child) h5:last-child {
    font-weight: 600;
}

#unitTooltip.tooltip>div:nth-child(2) {
    margin-top: -15px;
}

#unitTooltip.tooltip>div:last-child {
    padding-bottom: 10px;
}

#unitTooltip.tooltip>div:first-child span {
    display: block;
    width: 2px;
    background-color: var(--color-secondary);
    margin: 2px 10px;
}

@keyframes anim {
    0% {
        visibility: visible;
    }

    100% {
        visibility: hidden;
    }
}

/* ---------------------------------- POPUP */

div.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 500;
}

div.popup>div.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000000;
    backdrop-filter: blur(0);
    transition: all 300ms;
}

div.popup .quitBtn {
    width: 50px;
    height: 50px;
    background-color: var(--color-primary);
    background-image: url(./icons/x.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 35%;
    cursor: pointer;
    transition: background-color 0.15s;
}

div.popup .quitBtn:hover {
    background-color: var(--color-primary-hover);
}

div.popup>div .p_Parent {
    width: calc(100% - 50px);
    height: 100%;
    background-color: var(--color-primary);
}

div.popup>div:last-child {
    position: absolute;
    display: flex;
    top: 100px;
    left: 100px;
    right: 100px;
    bottom: 100px;
    transition: all 300ms;
}

div.popup>div .p_PDF {
    position: absolute;
    right: 50px;
    height: 100%;
    width: 75%;
}

/* div.popup>div .p_PDF:hover div:first-child {
    opacity: 0.1;
} */

div.popup>div .p_PDF div:first-child {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: white;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    transition: opacity 0.15s;
    /* cursor: pointer; */
}

div.popup>div .p_PDF div:last-child {
    height: 100%;
    width: 100%;
    position: absolute;
}

div.popup>div .p_PDF div:last-child p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -90%);
    font-size: 1.35em;
    font-weight: 500;
    padding: 12px 30px;
    color: var(--color-secondary);
    border: solid var(--color-secondary) 3px;
}

div.popup>div .p_PDF div:last-child p::first-letter {
    text-transform: capitalize;
}

div.popup .p_Infos {
    position: absolute;
    height: 100%;
    width: calc(25% - 50px);
    display: flex;
    flex-direction: column;
}

div.popup .p_Infos h4 {
    margin: 0;
    color: var(--color-secondary);
    text-transform: uppercase;
    font-weight: 500;
}

div.popup .p_Infos .p_Text h4:first-child {
    font-weight: 300;
}

div.popup .p_Infos .p_Text {
    border-bottom: #ffffff23 solid 1px;
    padding: 0;
    padding-bottom: 2px;
    margin: 15px 35px;
    font-size: 0.9vw;
}

div.popup .p_Infos>div:not(.p_Title, .bottom) {
    padding-top: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: calc(100% - 150px); */
    flex-grow: 1;
}

div.popup .p_Infos>div>div {
    display: flex;
    justify-content: space-between;
    padding: 15px 35px;
}

div.popup .p_Infos>div.bottom {
    background-color: var(--color-primary-hover);
}

div.popup .p_Infos>div>div.btnBottom {
    justify-content: center;
    align-items: center;
    color: var(--color-secondary);
    cursor: pointer;
    padding: 0;
    /* width: fit-content; */
}

div.popup .p_Infos>div>div.btnBottom:hover {
    text-decoration: underline;
}

div.popup .p_Infos>div>div.btnBottom p {
    font-size: 0.8em;
    margin-left: 10px;
}

div.popup .p_Infos>div>div.btnBottom p::first-letter {
    text-transform: capitalize;
}

div.popup .p_Infos div.p_Desc {
    flex-direction: column;
    font-size: 0.8vw;
}

div.popup .p_Infos div.p_Desc h4 {
    text-transform: none;
}

div.popup .p_Infos div.p_Desc li {
    color: var(--color-secondary);
    margin: 0;
    margin-top: 10px;
    font-weight: 300;
}

div.popup .p_Infos .p_Title {
    background-color: var(--color-primary-hover);
    font-size: 2vw;
    display: flex;
    justify-content: space-between;
    padding: 15px 35px;
}

div.popup .p_Infos .p_Title h4:last-child {
    font-weight: 700;
}


/* ANIM */
div.popup.fadeIn>div.bg {
    background-color: #000000dd;
    backdrop-filter: blur(5px);

}

div.popup.fadeOut>div.bg {
    background-color: #00000000;
    backdrop-filter: blur(0);
}

div.popup.fadeIn>div:last-child {
    opacity: 1;
    transform: translateY(0);

}

div.popup.fadeOut>div:last-child {
    opacity: 0;
    transform: translateY(50%);
}

/*------------------- LOADING PAGE*/

div.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-secondary);
    /* background-image: url("https://symbiohabitat.ca/wp-content/uploads/2023/07/ic-developpement.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60px; */
    z-index: 9999;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    transform: translate(-50%, -50%);
}

.loader span {
    position: relative;
    display: block;
    background: var(--color-primary);
    height: 131px;
    width: 16px;
    top: -15px;
    animation: escaleY 1.5s infinite ease-in-out;
    animation-delay: -0.15s;
}

.loader span:first-child {
    left: -6px;
    top: 15px;
    animation-delay: -0.30s;
}

.loader span:last-child {
    left: 6px;
    top: 0px;
    animation-delay: 0.15s;
}


@keyframes escaleY {

    0%,
    100% {
        transform: translateY(15px);
    }

    50% {
        transform: translateY(-15px);
    }
}