#mobileMenu,
#mobileFilters,
#filtersTop {
    display: none;
}

@media only screen and (max-width: 1280px) {
    #compass {
        width: 45px;
        height: 36px;
    }

    div.rangeParent {
        padding: 0 20px;
    }

    div.rangeDiv {
        margin: 0 calc(2vw);
    }

    #level {
        padding: 10px;
        font-size: 1em;
    }

    /* Popup */
    div.popup>div:last-child {
        top: 50px;
        left: 50px;
        right: 50px;
        bottom: 50px;
    }

    div.popup .p_Infos .p_Title {
        font-size: 1.5em;
        padding: 15px;
    }

    div.popup .p_Infos .p_Text {
        margin: 15px;
        font-size: 0.8vw;
    }

    div.popup .p_Infos>div:not(.p_Title, .bottom) {
        padding-top: 15px;
    }

    div.popup .p_Infos>div>div {
        padding: 15px;
    }

    div.popup .p_Infos>div>div ul {
        padding-left: 30px;
    }
}

@media only screen and (max-width: 900px) {
    #compass {
        right: 15px;
        bottom: 150px;
    }

    #level {
        left: 15px;
        top: 15px;
    }

    #building {
        width: 100%;
        height: calc(100% - 50px);
        z-index: 500;
        position: absolute;
        background-image: url("../public/images/SYMBIO_Building.jpg");
        background-size: contain;
        background-position: center;
        transition: transform 250ms;
    }

    .plans #building {
        transform: translateX(-100%);
    }

    #overlayBuilding {
        width: 100%;
        height: 100%;
    }

    .imp-container {
        justify-content: center;
    }

    #building #image-map-pro-building {
        background-color: #435030ad;
        backdrop-filter: blur(20px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #image-map-pro-building .imp-canvas {
        height: initial;
        max-height: calc(100vh - 50px);
    }

    #levels {
        width: 100%;
    }

    #mobileMenu {
        display: block;
        position: fixed;
        bottom: 0;
        display: flex;
        width: 100%;
        background-color: var(--color-primary);
        z-index: 500;
        height: 50px;
        box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
    }

    #mobileMenu div {
        flex-grow: 1;
        color: var(--color-secondary);
        font-weight: 300;
        text-transform: uppercase;
        text-align: center;
        line-height: 50px;
    }

    #mobileMenu div.active {
        background-color: var(--color-primary-hover);
        font-weight: 600;
    }

    #interface {
        background-color: var(--color-primary);
        height: 150px;
        width: 100%;
        bottom: 50px;
        position: fixed;
        height: 50px;
        z-index: 100;
    }

    #overlayUnits {
        width: 100%;
        height: calc(100vh - 100px);
        z-index: 50;
        transition: opacity 150ms;
    }

    .filtersOpenend #overlayUnits {
        opacity: 0;
    }

    div.rangeParent {
        height: calc(100% - calc(100vh - 100%) - 120px);
        width: 100%;
        padding: 0;
        position: initial;
        top: 0;
        background-color: var(--color-primary);
        flex-direction: column;
        align-items: stretch;
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    #image-map-pro-lvl {
        top: calc(50% - 150px);
        max-height: calc(100vh - 100px) !important;
        max-width: calc((100vh - 100px) * (1600 / 1100));
    }
    /* #interface .top {        
        z-index: 201;
        position: fixed;
        width: 100%;
    } */
    
    div.filtersParent {
        flex-direction: column;
        z-index: 200;
        transition: transform 250ms;
        transform: translateX(-100%);
        position: fixed;
        top:0;
        width: 100%;
        height: calc(100vh - calc(100vh - 100%));
    }
    .filtersOpenend div.filtersParent {
        transform: translateX(0%);
    }

    div.rangeDiv {
        margin: 0 25px;
        justify-content: center;
        min-height: 100px;
    }

    #mobileFilters {
        display: block;
        height: 50px;
        width: 50px;
        position: fixed;
        bottom: 115px;
        left: 15px;
        background-color: var(--color-primary);
        color: var(--color-secondary);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #filtersTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 25px;
        margin: 0 25px;
        background-color: var(--color-primary-hover);
        width: calc(100% - 50px)
    }

    #filtersTop h4 {
        color: var(--color-secondary);
        text-transform: capitalize;
        font-size: 1.75em;
        font-weight: 400;
        margin: 0;
    }

    #filtersTop div {
        width: 40px;
        height: 40px;
        background-color: var(--color-secondary);
        background-image: url('./icons/x2.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;
    }

    #byEdge {
        bottom: 63px;
        left: inherit;
        right: 15px;
        z-index: 400;
        transition: all 150ms;
    }
    .levels #byEdge {
        z-index: 500;
    }

    .plans #byEdge {
        bottom: 115px;
        filter: brightness(0);
    }

    .filtersOpenend #byEdge {
        opacity: 0;
    }

    /* Popup */
    div.popup>div:last-child {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    div.popup .p_Infos>div>div.btnBottom p {
        font-size: 1.2vw;
    }
    div.popup>div:last-child {
        display: block;
    }
    div.popup>div .p_Parent {
        width: 100%;
    }
    div.popup .quitBtn {
        position: absolute;
        top:0;
        right: 0;
        z-index: 10;
    }
    div.popup>div .p_PDF {
        position: absolute;
        right: 0;
        top:0;
        height: 100%;
        z-index: 5;
        background-color: white;
    }
    div.popup>div .p_PDF div:last-child {
        height: calc(100% - 50px);
        margin-top:50px;
    }
    div.popup .p_Infos {
        width: 25%;
    }
    div.popup {
        position: fixed;
        top: 0;
        left: 0;
        bottom:0;
        right:0;
        width: 100%;
        height: 100%;
        z-index: 500;
    }
    div.numbers {
        font-size: 3vh;
    }
    #legend{        
        bottom:auto;
        left:auto;
        top:5px;
        right: 5px;
    }
    #legend span {
        width: 10px;
        height: 10px;
        margin-right: 5px;
    }
    #legend p {
        font-size: 0.5em;
    }
    .filtersOpenend #legend{
        z-index: 50;
    }
    
}
@media only screen and (max-width: 900px) and (orientation: portrait) {
    div.numbers {
        font-size: 2.8vw;
    }
    div.popup>div .p_Parent {
        display: flex;
        flex-direction: column-reverse;
    }
    div.popup .p_Infos {
        position: initial;
    }
    div.popup>div .p_PDF {
        position: initial;
        width: 100%;
        height: 35%;
        z-index: 5;
        background-color: white;
    }
    div.popup>div .p_PDF div:last-child {
        height: calc(35% - 50px);
        margin-top:50px;
    }
    div.popup .p_Infos {
        width: 100%;
        height: 65%;
    }
    div.popup .p_Infos .p_Text {
        font-size: 3.5vw;
    }
    div.popup .p_Infos>div>div.btnBottom p {
        font-size: 4vw;
    }
    div.popup .p_Infos div.p_Desc {
        font-size: 3vw;
    }
}

/* @media only screen and (max-width: 500px) {
    div.numbers {
        font-size: 1.5vh;
    }
} */